import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Button from "../components/Button";
import EventsOverview from "../components/EventsOverview";
import NewsOverview from "../components/NewsOverview";
import { GatsbyImage } from "gatsby-plugin-image";
import "./index.sass"
import CircleArrow from "../components/CircleArrow";
import { scrollToElement } from "../utils/utils";
import { SEO } from "../components/SEO";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  pageContext,
  image,
  title,
  heading,
  subheading,
  description,
  events,
  news
}) => {
  const heroImage = getImage(image) || image;
  return (
    <div className="section-container">
      <section className="section landing-section">
        <div className="container ">
          <div className="content landing-content">
            <h1>{title}</h1>
            <h3>{subheading}</h3>
            <h5>{description}</h5>
            <Link to="/events">
              <Button text="Zaczynamy!" />
            </Link>
          </div>
          <GatsbyImage image={heroImage}
            alt="Hero image"
            className="hero-image"
          />
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <CircleArrow onClick={() => scrollToElement("events-overview-wrapper")} />
        </div>
      </section>
      <div className="section-container">
        <section className="section events-overview-wrapper" id="events-overview-wrapper">
          {events ? <EventsOverview events={events.slice(0, 5)} /> : null}
        </section>
      </div>
      <div className="section-container news-overview-wrapper full-width">
        <section className="section">
          {news ? <NewsOverview news={news.slice(0, 4)} /> : null}
        </section>
      </div>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  events: PropTypes.array,
  news: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges } = data.allMarkdownRemark;
  const events = edges.filter(edge => edge.node.frontmatter.templateKey === "event")
  const news = edges.filter(edge => edge.node.frontmatter.templateKey === "news")
  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        events={events}
        news={news}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default IndexPage;

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title 
        heading
        subheading
        description
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {in: ["event", "news"]}}}
      sort: {fields: frontmatter___publishDate, order: DESC}
      ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            date
            description
            heading
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            title
            subheading
            publishDate
            startDate
          }
        }
      }
    }
  }
`;
