import React from "react";
import PropTypes from "prop-types";
import "./events-overview.sass";
import Event from "./Event";
import RedirectHeader from "./RedirectHeader";
import { Link } from "gatsby";
import CircleArrow from "./CircleArrow";

const EventsOverview = ({ events, showRedirect }) => {
  return (
    <div className="events-overview-wrapper">
      <RedirectHeader title="Wydarzenia" route="/events" showRedirect={showRedirect} />
      <div className="events-overview">
        {events.map((event) => (
          <Event key={event.node.id} event={event.node.frontmatter} slug={event.node.fields.slug} />
        ))
        }
      </div>
      <Link to="/events" className="mobile-overview-link" style={{
        gap: "1rem",
        alignItems: "center",
        justifyContent: "end",
      }}>
        <h6>Zobacz więcej</h6>
        <CircleArrow direction="right" />
      </Link>
    </div>
  );
}

export default EventsOverview;