import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import "./event.sass";
import { formatDate } from "../utils/utils";
import { Link } from "gatsby";

const Event = ({ event, slug }) => {
  const image = getImage(event.image) || event.image;
  const { title, startDate } = event;
  return (
    <Link to={slug} className="event-overview-item">
      <GatsbyImage image={image} alt="Event image" style={{ width: "100%", height: "500px" }} />
      <h5 className="h5-black">{title}</h5>
      <h6>{formatDate(new Date(startDate))}</h6>
    </Link>
  )
}

Event.propTypes = {
  event: PropTypes.object,
}

export default Event;